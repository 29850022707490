// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import profile from './profile';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, profile });

export default reducers;
