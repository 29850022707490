import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import CryptoJS from 'crypto-js';

const KEY = process.env.REACT_APP_STORAGE_KEY;
const iterations = 234;
// const bytesInSalt = 128 / 8;
// const salt = CryptoJS.lib.WordArray.random(bytesInSalt);
const salt = 'a11413fc69c1a00a3516e932512defd8';

var iv128Bits = CryptoJS.PBKDF2(KEY, salt, { keySize: 128 / 32, iterations: iterations });
var key256Bits = CryptoJS.PBKDF2(KEY, salt, { keySize: 256 / 32, iterations: iterations });

export function encrypt(plainText) {
    const cipherText = CryptoAES.encrypt(plainText, key256Bits, {
        iv: iv128Bits
    });
    return cipherText.toString();
}

export function decrypt(cipherText) {
    const plainText = CryptoAES.decrypt(cipherText, key256Bits, {
        iv: iv128Bits
    });
    return plainText.toString(CryptoENC);
}
