import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    institutionCode: '',
    institutionName: '',
    email: '',
    language: 'tr'
};

const profile = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        authenticate(state, action) {
            state.institutionCode = action.payload.institutionCode;
            state.institutionName = action.payload.institutionName;
            state.email = action.payload.email;
            state.language = action.payload.languageOfTheInstitution;
        }
    }
});

export default profile.reducer;

export const { authenticate } = profile.actions;
