import { CardHeader, CardContent, Typography, Card, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

const InvoiceCollectionCard = ({ data }) => {
    const { t } = useTranslation();

    return (
        <Card>
            <CardHeader title={t('isin_code') + ' : ' + data.isinCode}></CardHeader>
            <CardContent>
                <Typography variant="h4">{t('collection')}</Typography>
                <Typography>
                    {t('total_amount') + ' : ' + data?.total?.totalAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' ₺'}
                </Typography>
                <Typography>
                    {t('amount') + ' : ' + data?.total?.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' ₺'}
                </Typography>
                <Typography>
                    {t('total_tax_amount') + ' : ' + data?.total?.taxAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' ₺'}
                </Typography>
                <Typography>
                    {t('total_penalty_amount') +
                        ' : ' +
                        data?.total?.penaltyAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                        ' ₺'}
                </Typography>
                <Divider />
                <Typography variant="h4">{t('cancelled_collection')}</Typography>
                {data?.cancel == undefined ? (
                    <Typography>{t('no_records_found')}</Typography>
                ) : (
                    <>
                        <Typography>
                            {t('total_amount') + ' : ' + data?.cancel?.totalAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' ₺'}
                        </Typography>
                        <Typography>
                            {t('amount') + ' : ' + data?.cancel?.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' ₺'}
                        </Typography>
                        <Typography>
                            {t('total_tax_amount') +
                                ' : ' +
                                data?.cancel?.taxAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                                ' ₺'}
                        </Typography>
                        <Typography>
                            {t('total_penalty_amount') +
                                ' : ' +
                                data?.cancel?.penaltyAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                                ' ₺'}
                        </Typography>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default InvoiceCollectionCard;
