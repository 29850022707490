import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import * as React from 'react';

// material-ui
import { ButtonBase } from '@mui/material';

// project import
import config from 'config';
import LogoPng from 'assets/images/TeamSec_Logo.png';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ sx, to }) => {
    const { i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    function changeLanguageEN() {
        i18n.changeLanguage('en');
        setAnchorEl(null);
    }

    function changeLanguageTR() {
        i18n.changeLanguage('tr');
        setAnchorEl(null);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <ButtonBase disableRipple component={Link} to={!to ? config.defaultPath : to} sx={sx}>
                <img width="180" height="50" src={LogoPng} alt="" loading="lazy" />
            </ButtonBase>
            {/* <div>
                <IconButton
                    id="basic-button"
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <DownOutlined />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button'
                    }}
                >
                    <MenuItem onClick={changeLanguageTR}>TR</MenuItem>
                    <MenuItem onClick={changeLanguageEN}>EN</MenuItem>
                </Menu>
            </div> */}
        </>
    );
};

LogoSection.propTypes = {
    sx: PropTypes.object,
    to: PropTypes.string
};

export default LogoSection;
