// material-ui
import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import api from 'api/base-api';
import MainCard from 'components/MainCard';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '../../../../node_modules/@mui/material/index';
import InvoiceCollectionCard from './InvoiceCollectionCard';
import NoContentCard from '../../../components/NoContentCard';

const InvoiceCollectionTotal = () => {
    const [isinCode, setIsinCode] = useState('');
    const [fundCode, setFundCode] = useState('');
    const [startDate, setStartDate] = useState(getCurrentDate());
    const [institution, setInstitution] = useState({});

    const [reportData, setReportData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [messageState, setMessageState] = useState({
        openMessage: false,
        vertical: 'top',
        horizontal: 'center'
    });

    const [institutions, setInstitutions] = useState([]);

    const { vertical, horizontal, openMessage } = messageState;

    const { t } = useTranslation();
    const [filter, setFilters] = useState();

    const handleCloseMessage = () => setMessageState({ ...messageState, openMessage: false });
    const handleOpenMessage = (newMessageState) => setMessageState({ openMessage: true, ...newMessageState });

    //----------------------------------
    //-----Filter Event-------------
    const handleFilter = async (e) => {
        setLoading(true);
        e.preventDefault();

        var validateDate = startDate == '' ? false : true;
        var validateInstitution = institution != null && institution.id ? true : false;

        if (validateDate != true) {
            setSeverity('error');
            setMessage(t('select_start_and_end_date'));
            handleOpenMessage({
                vertical: 'top',
                horizontal: 'center'
            });
            setLoading(false);
            return;
        }

        if (validateInstitution != true) {
            setSeverity('error');
            setMessage(t('select_institution'));
            handleOpenMessage({
                vertical: 'top',
                horizontal: 'center'
            });
            setLoading(false);
            return;
        }

        var strDate = startDate.replaceAll('-', '');

        const config = {
            params: {
                institutionCode: institution.code,
                isinCode: isinCode,
                fundCode: fundCode,
                date: strDate
            }
        };
        setFilters(config);

        try {
            const response = await api.get('/report/invoice/collections', config);

            console.log('call report api with:', config);

            setReportData(response.data);

            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity('error');
            if (err.response) {
                if (err.response.status !== 401) setMessage(err.response.data.Message);
                else setMessage(err.response.statusText);
            } else {
                setMessage(err.message);
            }

            handleOpenMessage({
                vertical: 'top',
                horizontal: 'center'
            });
        }
    };

    const defaultPropsForInstitution = {
        options: institutions,
        getOptionLabel: (option) => option.name || '',
        isOptionEqualToValue: (option, value) => option.id === value.id
    };

    const getInstitutions = async () => {
        try {
            const config = {
                params: {
                    pageSize: 100
                }
            };
            const response = await api.get('/institution', config);
            setInstitutions(response.data.data);
        } catch (err) {
            setSeverity('error');
            if (err.response) {
                if (err.response.status !== 401) {
                    setMessage(err.response.data.Message);
                } else {
                    setMessage(err.response.statusText);
                    setTimeout(() => {
                        navigate('/login');
                    }, 1000);
                }
            } else {
                setMessage(err.message);
            }

            handleOpenMessage({
                vertical: 'top',
                horizontal: 'center'
            });
        }
    };

    function getCurrentDate() {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Aylar 0-11 arasında olduğu için +1 eklenir
        const day = String(today.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        getInstitutions();
    }, []);

    return (
        <>
            <Grid container direction="column" spacing={4}>
                <Grid item>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h3">Dashboard</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <MainCard title={t('filter')}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    value={institution}
                                    onChange={(event, newValue) => {
                                        setInstitution(newValue);
                                    }}
                                    disablePortal
                                    id="institutions"
                                    {...defaultPropsForInstitution}
                                    renderInput={(params) => <TextField fullWidth {...params} label={t('select_institution')} />}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={fundCode}
                                    onChange={(e) => setFundCode(e.target.value)}
                                    fullWidth
                                    id="fundCode"
                                    label={t('fund_code')}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={isinCode}
                                    onChange={(e) => setIsinCode(e.target.value)}
                                    fullWidth
                                    id="isinCode"
                                    label={t('isin_code')}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    type="date"
                                    defaultValue="2024-02-21"
                                    fullWidth
                                    id="date"
                                    label={t('start_date')}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} align="right">
                                <Button variant="contained" onClick={handleFilter} disabled={loading}>
                                    {t('filter')}
                                </Button>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
                {loading ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid item>
                        {reportData.data?.length > 0 ? (
                            reportData.data.map((item) => (
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <InvoiceCollectionCard data={item} />
                                    </Grid>
                                </Grid>
                            ))
                        ) : (
                            <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                                <NoContentCard />
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
            <Snackbar
                open={openMessage}
                onClose={handleCloseMessage}
                autoHideDuration={3000}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
            >
                <Alert onClose={handleCloseMessage} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default InvoiceCollectionTotal;
