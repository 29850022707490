import { CardHeader, CardContent, Typography, Card, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

const InvoiceCollectionCard = ({ data }) => {
    const { t } = useTranslation();

    return (
        <Card>
            <CardContent>
                <Typography variant="h4">{t('no_records_found')}</Typography>
            </CardContent>
        </Card>
    );
};

export default InvoiceCollectionCard;
