import { ChromeOutlined, QuestionOutlined, FieldTimeOutlined } from '@ant-design/icons';

const icons = {
    ChromeOutlined,
    QuestionOutlined,
    FieldTimeOutlined
};

const support = {
    id: 'support',
    title: 'reports',
    type: 'group',
    children: [
        {
            id: 'report-all',
            title: 'reports',
            type: 'item',
            url: '/report-all',
            icon: icons.ChromeOutlined
        },
        {
            id: 'invoice-collection',
            title: 'invoice_collection',
            type: 'item',
            url: '/invoice-collection',
            icon: icons.ChromeOutlined
        },
        {
            id: 'report-all-detail',
            title: 'detail_reports',
            type: 'item',
            url: '/report-all-detail',
            icon: icons.ChromeOutlined
        },
        {
            id: 'expected-collection-detail',
            title: 'expected_collection_detail',
            type: 'item',
            url: '/expected-collection-detail',
            icon: icons.ChromeOutlined
        },
        {
            id: 'rediscount-report',
            title: 'rediscount',
            type: 'item',
            url: '/rediscount-report',
            icon: icons.ChromeOutlined
        },
        {
            id: 'investor-report',
            title: 'investor_report',
            type: 'item',
            url: '/investor-report',
            icon: icons.ChromeOutlined
        }
        // {
        //     id: 'investor-cube-report',
        //     title: 'investorcube_report',
        //     type: 'item',
        //     url: '/investorcube-report',
        //     icon: icons.ChromeOutlined
        // }
    ]
};

export default support;
