import { decrypt } from './security-utils';

export const getToken = () => {
    const cipherValue = localStorage.getItem(process.env.REACT_APP_STORAGE_KEY);
    var clearValue = '';
    if (cipherValue != null) {
        try {
            clearValue = decrypt(cipherValue);
        } catch (e) {
            console.log(e);
        }
    }

    return clearValue;
};

export const getPermissions = () => {
    const permissionsD = localStorage.getItem(process.env.REACT_APP_STORAGE_KEY_2);
    var permissionsC = [];
    if (permissionsD != null) {
        try {
            permissionsC = JSON.parse(decrypt(permissionsD));
        } catch (e) {
            console.log(e);
        }
    }

    return permissionsC;
};
