import axios from 'axios';
import { getToken } from 'utils/token-utils';
import { useNavigate } from 'react-router-dom';

const options = {
    baseURL: process.env.REACT_APP_BASE_API_URL,
    withCredentials: false,
    mode: 'cors',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'application/json'
    }
};

const axiosInstance = axios.create(options);

axiosInstance.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
});

// axiosInstance.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     (error) => {
//         if (error.response.status === 401) {
//             var url = process.env.REACT_APP_BASE_WEB_URL + 'login';
//             console.log(url);
//             window.location.replace(url);
//         }
//         return error;
//     }
// );

export default axiosInstance;
