import { lazy } from 'react';

import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import InvoiceCollectionTotal from 'pages/reports/invoices/InvoiceCollection';
import { element } from 'prop-types';

const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

const ReportAll = Loadable(lazy(() => import('pages/reports/ReportAll')));
const ReportAllDetail = Loadable(lazy(() => import('pages/reports/ReportAllDetail')));
const ExpectedCollectionDetail = Loadable(lazy(() => import('pages/reports/ExpectedCollectionDetail')));
const RediscountReport = Loadable(lazy(() => import('pages/reports/RediscountReport')));
const DelayDaysReport = Loadable(lazy(() => import('pages/reports/DelayDaysReport')));
const InvestorReport = Loadable(lazy(() => import('pages/reports/investor-reports/InvestorReport')));
const InvestorCubeReport = Loadable(lazy(() => import('pages/reports/investor-reports/InvestorCubeReport')));

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'report-all',
            element: <ReportAll />
        },
        {
            path: 'invoice-collection',
            element: <InvoiceCollectionTotal />
        },
        {
            path: 'report-all-detail',
            element: <ReportAllDetail />
        },
        {
            path: 'expected-collection-detail',
            element: <ExpectedCollectionDetail />
        },
        {
            path: 'rediscount-report',
            element: <RediscountReport />
        },
        {
            path: 'delaydays-report',
            element: <DelayDaysReport />
        },
        {
            path: 'investor-report',
            element: <InvestorReport />
        },
        {
            path: 'investorcube-report',
            element: <InvestorCubeReport />
        }
    ]
};

export default MainRoutes;
